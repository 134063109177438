<script setup lang="ts">
import { computed } from 'vue'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerFaqSlider from '/~/components/drawer/views/settings/faq/components/drawer-faq-slider.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useUI } from '/~/composables/ui'
import { useUserEvents } from '/~/composables/user'
import getBenefitsRecords from '/~/modals/how-it-works/content/hiw-benefits'
import getStepsRecords from '/~/modals/how-it-works/content/hiw-steps'

const { hideEwalletMenu } = useUI()
const {
  isDarkThemeForEwallet,
  faqQuestions,
  howItWorks,
  benefits,
  ewalletLabels,
} = useCms()
const { isMethodAvailable } = usePaymentMethods()
const { isPhysicalEnabled } = useGiftcards()
const { faqVisited } = useUserEvents()

const props = defineProps<{
  back?: 'close'
}>()

faqVisited({
  faq: faqQuestions.value,
})

const ewalletEnabled = computed(() =>
  isMethodAvailable(PaymentMethodType.eWallet)
)
const steps = computed(() => {
  const slides = howItWorks.value.items?.length
    ? howItWorks.value.items
    : getStepsRecords({
        isPhysicalEnabled: isPhysicalEnabled.value,
        ewalletLabels: ewalletLabels.value,
      })

  return slides.map((item) => ({
    ...item,
    text: item.description,
  }))
})
const benefitsItems = computed(() => {
  const slides = benefits.value.items?.length
    ? benefits.value.items
    : getBenefitsRecords({
        ewalletEnabled: ewalletEnabled.value,
        ewalletLabels: ewalletLabels.value,
      })

  return slides.map((item: any) => {
    item.text = item.description
    return item
  })
})

const onBack = props.back === 'close' ? hideEwalletMenu : undefined
</script>

<template>
  <base-aside-page
    :title="howItWorks.title ?? 'How it Works'"
    no-padding
    :back="onBack"
  >
    <div class="px-5 pb-5">
      <div
        class="mt-2.5 rounded-lg p-5"
        :class="{
          'bg-gray-50': !isDarkThemeForEwallet,
          'bg-gray-800': isDarkThemeForEwallet,
        }"
      >
        <drawer-faq-slider :slides="steps" class="w-full" />
      </div>
      <div
        class="mt-[30px] font-bold"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
        }"
      >
        {{ benefits.title ?? 'Benefits of eWallet' }}
      </div>
      <div
        class="mt-2.5 rounded-lg p-5"
        :class="{
          'bg-gray-50': !isDarkThemeForEwallet,
          'bg-gray-800': isDarkThemeForEwallet,
        }"
      >
        <drawer-faq-slider :slides="benefitsItems" class="w-full" />
      </div>
      <template v-if="faqQuestions.length">
        <div
          class="mt-[30px] font-bold"
          :class="{
            'text-eonx-neutral-600': !isDarkThemeForEwallet,
          }"
        >
          Frequently Asked Questions
        </div>
        <div class="mt-4">
          <div
            v-for="(question, index) of faqQuestions"
            :key="index"
            :class="index < faqQuestions.length - 1 && 'border-b-2'"
          >
            <base-collapse :autoscroll="false" :show-icon="true">
              <template #icon="{ visible }">
                <base-icon
                  :svg="visible ? 'minus' : 'v2/custom/plus'"
                  color="primary"
                  :size="12"
                  class="flex-shrink-0"
                />
              </template>
              <template #trigger>
                <div
                  class="mr-2.5 py-2.5 font-bold"
                  :class="{
                    'text-eonx-neutral-800': !isDarkThemeForEwallet,
                  }"
                >
                  {{ question.title }}
                </div>
              </template>
              <div class="pb-5 text-eonx-neutral-600" v-html="question.text" />
            </base-collapse>
          </div>
        </div>
      </template>
    </div>
  </base-aside-page>
</template>
